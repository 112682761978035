import React, { useState, useContext, useRef } from "react";
import { LanguageContext } from "../lang";
import Dutch from "../components/GeneralConditions/de";
import English from "../components/GeneralConditions/en";
import Layout from "../components/Layout/layout";

export default () => {
  const {
    translations: { home },
  } = useContext(LanguageContext);
  const { culture } = home.dateTimePicker;
  return (
    <Layout>
      <br />
      <br />
      <br />
      <br />
      {culture === "en" ? <English /> : <Dutch />}
    </Layout>
  );
};
