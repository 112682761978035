import React from "react";
import Section from "./section";

export default () => (
  <div style={{ padding: 20 }}>
    <h2>Algemene Voorwaarden</h2>
    <br />
    <div>
      <b>
        Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen sorgfältig durch,
        bevor Sie unsere Dienste nutzen.
      </b>
    </div>
    <br />
    <Section heading="1) Definitionen">
      <p>
        Allgemeine Geschäftsbedingungen: Diese Allgemeinen Geschäftsbedingungen
        bis zum zuletzt festgelegten Text.
      </p>
      <p>
        Unternehmer: Taxionspot Handel unter dem Namen Taxionspot.nl oder
        schnellintaxi, befindetsich in houckgeeststraat 13, 5643 USA in
        Eindhoven und registriert bei der Handelskammer unter der Nummer
        65752376. aan de
      </p>
      <p>
        Kunde: Besucher, natürliche oder juristische Person, die eine oder
        mehrere Buchungen über taxionspot.nl platziert und damit einen
        Transportvertrag mit der Fluggesellschaft abschließt.
      </p>
      <p>Parteien: Unternehmer und Kunde zusammen.</p>
      <p>
        Buchung: Eine oder mehrere Tarifbestellungen über taxionspot.nl, in dem
        der Unternehmer sie an die Fluggesellschaft weiterleitet, und spielt
        eine erleichternde Rolle bei der Zahlung der gebuchten Buchungen.
      </p>
      <p>Fahrt: Transport vom Kunden bei Entrepreneur per Taxi gebucht.</p>
      <p>
        Angebot: Das Angebot von Entrepreneur nach der aufgegebenen
        FahrtBestellung durch den Kunden über taxionspot.nl.
      </p>
      <p>
        Carrier: Ein Unternehmen, das eine Fahrt für Unternehmer durchführt und
        zu diesem Zweck einen Transportvertrag mit Unternehmer abgeschlossen
        hat.
      </p>
      <p>
        Fahrer: Die Person, die von Carrier für die Durchführung der Fahrt
        beschäftigt ist.
      </p>
      <p>
        Kundentarif: Der Tarif für eine Fahrt, die der Kunde vom Unternehmer
        berechnet.
      </p>
    </Section>
    <Section heading="2) Dienstleistungen">
      <p>
        Entrepreneur bietet eine technische Plattform, die es dem Kunden
        ermöglicht, Fahrten von einem Carrier zu buchen und zu nehmen.
      </p>
      <p>
        Der Unternehmer fungiert als Vermittler zwischen Kunde und
        Fluggesellschaft und spielt eine erleichternde Rolle bei der Zahlung der
        gebuchten Buchungen.
      </p>
      <p>
        Der Transportvertrag wird zwischen Kunde und Fluggesellschaft durch eine
        Tarifbestellung des Kunden über taxionspot.nl.
      </p>
      <p>
        Die Identität des Carriers wird dem Kunden sofort nach der Buchung der
        gebuchten Fahrt per E-Mail und SMS bekannt gegeben. en sms.
      </p>
      <p>
        Es ist möglich, dass der Carrier of a Ride geändert wird, der Kunde
        erhält eine rechtzeitige Nachricht per E-Mail und sms.
      </p>
      <p>
        Der Kunde erkennt an, dass der Unternehmer keine
        Transportdienstleistungen erbringt oder als Transportunternehmen agiert
        und dass der Transport von Carrier erbracht wird, der nicht vom
        Unternehmer beschäftigt ist.
      </p>
    </Section>
    <Section heading="3) Anwendbarkeit">
      <p>
        Die Allgemeinen Geschäftsbedingungen gelten für die Buchung und den Kauf
        von Reisen durch den Kunden über taxionspot.nl.
      </p>
      <p>
        Der Kunde akzeptiert die Anwendbarkeit dieser Allgemeinen
        Geschäftsbedingungen, sobald der Kunde eine Buchung über taxionspot.nl
        und schließt daher einen Transportvertrag mit der Fluggesellschaft ab.
      </p>
      <p>
        Die Geschäftsbedingungen gelten in vollem Umfang; diese Bestimmungen
        können nur nach ausdrücklicher schriftlicher Genehmigung von The
        Entrepreneur schriftlich abweichen.
      </p>
      <p>
        Die Allgemeinen Geschäftsbedingungen werden dem Kunden digital zur
        Verfügung gestellt, bevor er eine endgültige Buchung vornimmt.
      </p>
      <p>
        Sofern nicht ausdrücklich anders und schriftlich vereinbart, ist die
        Anwendbarkeit anderer Geschäftsbedingungen ausgeschlossen.
      </p>
      <p>
        Wenn eine oder mehrere Bestimmungen dieser Allgemeinen
        Geschäftsbedingungen jederzeit teilweise oder vollständig für nichtig
        erklärt werden oder vernichtet werden könnten, bleiben die übrigen
        Bedingungen in vollem Umfang gültig. In diesem Fall werden der Kunde und
        Unternehmer Konsultationen einschließen, um sich auf die Ersetzung der
        Nonne oder zerstörte Bestimmungen zu einigen. Zweck und Umfang der
        ursprünglichen Bestimmungen sind so weit wie möglich zu beachten.
      </p>
      <p>
        Im Falle von Ungewissheit über die Auslegung einer oder mehrerer
        Bestimmungen dieser Allgemeinen Geschäftsbedingungen sollte die
        Erklärung im Geiste dieser Bedingungen erfolgen.
      </p>
      <p>
        Sollte es zu einer Situation zwischen Kunde und Unternehmer kommen, die
        in diesen Geschäftsbedingungen nicht geregelt ist, sollte diese
        Situation im Geiste dieser Bedingungen beurteilt werden.
      </p>
      <p>
        Der Unternehmer kann diese Allgemeinen Geschäftsbedingungen von Zeit zu
        Zeit ändern. Die geänderte Version wird auf taxionspot.nl veröffentlicht
        und gilt für die vom Kunden platzierte Buchung. Dem Kunden wird
        empfohlen, die Allgemeinen Geschäftsbedingungen (wieder) zu lesen, bevor
        er eine (neue) Buchung veröffentlicht.
      </p>
    </Section>
    <Section heading="4) Kundenanforderungen">
      <p>
        Das Mindestalter für die Buchung einer Fahrt durch taxionspot.nl beträgt
        18 Jahre oder älter. Der Kunde erklärt, dass er bei der Buchung der
        Fahrt alt ist. Der Unternehmer hat das Recht, das Alter des Kunden zu
        überprüfen. Entspricht dies nicht dem tatsächlichen Alter, hat der
        Unternehmer das Recht, den Vertrag mit sofortiger Wirkung zu kündigen
        und die Fahrt nicht durchzuführen. Der Kunde hat in diesem Fall keinen
        Anspruch auf Rückerstattung.
      </p>
      <p>
        Bei der Bestellung einer Fahrt gibt der Kunde eine gültige
        E-Mail-Adresse und Telefonnummer ein, an die er über taxionspot.nl
        erreichbar ist. Der Kunde ist verantwortlich für die Richtigkeit seiner
        Daten sowie für die Richtigkeit der Buchung einer Buchung.
      </p>
      <p>
        Der Unternehmer ist berechtigt, den Kunden von taxionspot.nl zu sperren
        und buchungen Fahrten unmöglich zu machen. Wenn der Unternehmer sich
        dazu entschließt, wird der Unternehmer kunde unverzüglich per E-Mail und
        SMS informieren.
      </p>
    </Section>
    <Section heading="5) Unternehmerpflichten">
      <p>
        Der Unternehmer wird alles tun, um das ordnungsgemäße Funktionieren der
        taxionspot.nl zu gewährleisten,kann aber nicht garantieren, dass
        siejederzeit voll und kontinuierlich funktioniert. Der Unternehmer wird
        sich immer mit größtmöglichen Anstrengungen bemühen, Störungen oder
        andere Probleme mit der Funktionalität und Zugänglichkeit so schnell wie
        möglich zu lösen.
      </p>
      <p>
        Der Kunde kann Unternehmerfragen stellen und innerhalb von 7 Tagen nach
        der Durchführung der Fahrt Beschwerden über die Abwicklung der Buchung
        und/oder den Service des Beförderers einreichen. Nach dieser Zeit hat
        taxionspot das Recht, die Prüfung der Beschwerde einzustellen.
      </p>
      <p>
        Der Unternehmer ist für die Verarbeitung der Kundenzahlung in Bezug auf
        die vom Kunden gebuchte Buchung verantwortlich. Der Unternehmer trägt
        das Schuldnerrisiko bei Nichtzahlung durch den Kunden und ist
        berechtigt, Inkassomaßnahmen einzuleiten.
      </p>
    </Section>
    <Section heading="6) Das Angebot">
      <p>
        Das Angebot des Unternehmers im Namen des Carriers an den Kunden ist
        unverbindlich. Der Unternehmer ist berechtigt, das Angebot zu ändern und
        anzupassen. Wenn Unternehmer zu diesem Zweck fortfährt, informiert sie
        den Kunden.
      </p>
      <p>
        Jedes Angebot enthält solche Informationen, dass dem Kunden klar ist,
        welche Rechte und Pflichten mit der Annahme des Angebots verbunden sind.
        Darüber hinaus enthält das Angebot eine vollständige und genaue
        Beschreibung der angebotenen Dienstleistungen. Die Beschreibung ist so
        detailliert, dass eine gute Beurteilung des Angebots durch den Kunden
        möglich ist.
      </p>
      <p>
        Alle Spezifikationen und Angaben im Angebot sind ein Hinweis, aus dem
        keine Rechte abgeleitet werden können und können keine Gründe für eine
        Entschädigung oder Kündigung des Vertrages sein.
      </p>
    </Section>
    <Section heading="7) Buchung einer Fahrt">
      <p>
        Kunden können eine Reise nach Eindhoven mit Entrepreneur in den
        Niederlanden über das Online- Buchungssystem auf taxionspot.nlbuchen.
        Der Kunde kann eine Fahrt für innerhalb von 20 Minuten buchen oder der
        Kunde kann eine Fahrt für einen späteren Zeitpunkt buchen.
      </p>
      <p>
        Bei Reisen vom Flughafen muss der Kunde bei der Bestellung der
        Fahrtbestellung die Flugdaten angeben, damit die Fluggesellschaft dies
        berücksichtigen kann. Wenn der Kunde die Flugdaten nicht weitergegeben
        hat, führt die vom Kunden angegebene Abholzeit für die Fluggesellschaft.
      </p>
      <p>
        Nach der Buchung erhält der Kunde eine sofortige Bestätigung der
        gebuchten Fahrt unter der angegebenen E-Mail-Adresse und eine SMS unter
        der angegebenen Telefonnummer. Nach dem Ende der Reise erhält der Kunde
        eine E-Mail-Adresse, um eine Bewertung zu hinterlassen.
      </p>
      <p>
        Sollten sich die vom Kunden übermittelten Daten nicht als richtig
        herausstellen, kann der Kunde diese auf seiner Übersichtsseite anpassen.
        Wenn dies nicht funktioniert, sollte der Kunde taxionspot kontaktieren.
      </p>
      <p>
        Der Kunde ist verantwortlich für die Richtigkeit seiner Daten, sowie für
        die Richtigkeit der Buchung und Überprüfung seiner Buchung. Der
        Unternehmer haftet nicht für fehlerhafte (Flug-)Daten, die vom Kunden
        übermittelt werden.
      </p>
      <p>
        Es ist möglich, zusätzliches Gepäck, Sondergepäck während der Fahrt
        mitzubringen. Wenn der Abtransport nicht möglich ist, wird der Spediteur
        den Kunden kontaktieren und eine Lösung gesucht werden.
      </p>
      <p>
        Wenn der Kunde so viel Gepäck mitnimmt, dass er die Benutzung eines
        Taxis oder mehrerer Taxis erfordert, muss der Kunde sicherstellen, dass
        er die richtige Transportkategorie oder mehrere Taxis bucht. Geschieht
        dies nicht, ist taxionspot berechtigt, zusätzliche Gebühren zu erheben.
      </p>
      <p>Es ist möglich, kleine Kinder während der Fahrt mitzunehmen.</p>
    </Section>
    <Section heading="8) Abbrechen oder Ändern einer Fahrt">
      Der Kunde hat bis 2 Stunden vor der vereinbarten Abholzeit das Recht, die
      Fahrt zu ändern oder zu stornieren. Alle gezahlten Beträge werden in
      voller Höhe zurückerstattet (abzüglich etwaiger Transaktionskosten). Eine
      Stornierung muss der Fluggesellschaft telefonisch sowie über die Online-
      Stornierungsumgebung von taxionspot mitgeteilt werden.
      Online-Stornierungen sind nur möglich, wenn der Kunde ein Konto bei
      taxionspot hat. Wenn sich der Ort der Abholung von Fahrten ändert, wird
      Ihnen eine zusätzliche Gebühr in Rechnung gestellt, wenn der Abholort über
      der zuvor angegebenen Adresse liegt. Diese müssen in bar mit Carrier
      abgerechnet werden. Dasselbe gilt für Haltestellen, die bei der Buchung
      nicht angegeben sind. Wenn die Fahrt weniger als 2{" "}
      <b>Stunden im Voraus storniert wird, hat der</b> Kunde keinen Anspruch auf
      Rückerstattung. Unternehmer ist berechtigt, eine gebuchte Fahrt bis zu 24
      Stunden im Voraus zu stornieren, aus welchem Grund auch immer. Der Kunde
      erhält den bereits zurückgezahlten Betrag zurück, ist dann aber für die
      Organisation (und Bezahlung) des Ersatztransports verantwortlich.
    </Section>
    <Section heading="9) Die Leistung einer Fahrt">
      <p>
        Der Fahrer wird den Kunden zum vereinbarten Zeitpunkt an der Haustür der
        vom Kunden angegebenen Adresse abholen. Wenn dieser Ort nicht mit dem
        Fahrzeug erreichbar ist, wird Chauffeur sein Fahrzeug so nah wie möglich
        abstellen und den Kunden darüber informieren.
      </p>
      <p>
        Bei Fahrten vom Flughafen muss der Kunde die Fluggesellschaft nach der
        Landung anrufen, um zu stimmen, wo sich gegenseitig betroffen ist.
      </p>
      <p>
        Der Kunde sollte in der Lage sein, sich beim Fahrer zu identifizieren,
        indem er eine Bestätigungs-E- Mail-Adresse oder Bestätigungs-SMS erhält.
      </p>
      <p>
        Der Kunde ist verantwortlich für die Richtigkeit seiner Daten, sowie für
        die Richtigkeit der Buchung und Überprüfung seiner Buchung. Wenn sich
        mehr Passagiere und/oder Gepäck in der Durchführung der Fahrt befinden,
        als der Kunde zum Zeitpunkt der Buchung abgeschlossen hat, ist der
        Fahrer nicht verpflichtet, die zusätzlichen Passagiere und/oder das
        Gepäck mitzubringen.
      </p>
      <p>
        Die Fahrt erfolgt in Übereinstimmung mit den Allgemeinen Bedingungen für
        den{" "}
        <a href="https://www.knv.nl/wp-content/uploads/2019/01/KNV_Taxi_av_taxi_september_2018_2paginas.pdf">
          Taxitransport des Gewerbevereins KNV Taxi.
        </a>{" "}
        Wenn diese Bedingungen in irgendeiner Hinsicht von diesen Allgemeinen
        Geschäftsbedingungen abweichen, haben diese Vorrang.
      </p>
      <p>
        Wenn 10 Minuten nach der vereinbarten Zeit kein Taxi beim Kunden
        angekommen ist, sollte der Kunde den Carrier immer zuerst über die
        angegebene Telefonnummer kontaktieren. Wenn der Telefonkontakt nicht
        erfolgreich ist oder Carrier angibt, dass das Taxi nicht rechtzeitig da
        sein wird, sollte sich der Kunde über die Notrufnummer von taxionspot
        melden. Diese Nummer findet Der Kunde auf der Website von taxionspot.
        Dann wird gemeinsam nach einer Lösung gesucht.
      </p>
    </Section>
    <Section heading="10) Keine Show Kunde und Wartezeit Treiber">
      <p>
        Der Kunde hat keinen Anspruch auf Rückerstattung, wenn es eine &quot;No
        Show&quot; gibt. Es gibt keine Show, wenn der Kunde nicht innerhalb von
        zehn Minuten nach der vereinbarten Abholzeit am vereinbarten Ort
        anwesend ist. Der Beförderer ist in der Zwischenzeit verpflichtet,
        nachweisbare Versuche zu unternehmen, den Kunden zu kontaktieren.
      </p>
      <p>
        Im Falle des Transports vom Flughafen gibt es eine andere Wartezeit für
        Chauffeur, d.h. eine Stunde und zehn Minuten nach der Landung des
        Kundenfluges. Die Flugdaten, die der Kunde bei Booking weitergegeben
        hat, dienen als Ausgangspunkt. Die Fluggesellschaft wird den Flug im
        Auge behalten. Der Kunde ist auch verpflichtet, die Fluggesellschaft
        über Verspätungen am Flughafen unter der Telefonnummer unter seiner
        Bestätigungs-E-Mail-Adresse <b>auf dem Laufenden zu halten.</b> Wenn der
        Kunde keine korrekte oder korrekte Flugnummer angegeben hat, tritt
        Artikel 10.1 automatisch in Kraft. Die abgeschlossene Abholzeit{" "}
        <b>wird beibehalten.</b>
      </p>
      <p>
        Bei längerer Wartezeit als eine Stunde und zehn Minuten nach der Landung
        des Flugzeugs ist Chauffeur berechtigt, nicht mehr warten zu müssen und
        gilt als Nichterscheinen. In Absprache mit dem Kunden kann vereinbart
        werden, zu warten. Der Fahrer ist berechtigt, eine Wartegebühr zu
        verlangen, die in bar im Taxi abgerechnet werden kann.
      </p>
      <p>
        Wenn sich die tatsächliche Landezeit so stark von der ursprünglichen
        Landezeit unterscheidet, dass es für Carrier schwierig wird, die Fahrt
        durchzuführen, kann in Absprache mit dem Taxion Spot eine Lösung gesucht
        werden. Sollte dies nicht funktionieren, ist Carrier berechtigt, die
        Fahrt nicht durchzuführen und die Fahrt wird storniert. Der Kunde erhält
        den vollen bezahlten Betrag zurück (abzüglich aller Transaktionskosten),
        ist aber für die Organisation (und Bezahlung) des Ersatztransports
        verantwortlich.
      </p>
    </Section>
    <Section heading="11) Zahlung">
      <p>
        Eine Reise, die vom Kunden mit Unternehmer gebucht wurde, muss im Voraus
        an Entrepreneur bezahlt werden. Die Buchung ist erst endgültig, wenn sie
        bezahlt ist.
      </p>
      <p>
        Die Zahlung kann über verschiedene Zahlungsmethoden erfolgen.
        Unternehmer können einen Aufpreis für bestimmte Zahlungsmethoden
        berechnen. Im Falle eines Aufpreises wird dies im Buchungsprozess für
        Zahlungsmethoden erwähnt.
      </p>
      <p>
        Mautgebühren und Fährgebühren sowie Kosten für zusätzliche Kilometer,
        die durch die Änderung der Route durch den Kunden nach der Einfahrt mit
        Chauffeur gefahren werden, müssen direkt an Chauffeur bezahlt werden.
      </p>
      <p>
        Jeder Tipp ist nicht im Preis inbegriffen. Dem Kunden steht es frei, dem
        Fahrer einen Tipp und die Höhe des Trinkgeldes zu geben.
      </p>
    </Section>
    <Section heading="12) Haftung">
      <p>
        Der Unternehmer fungiert als Vermittler für Carrier und spielt eine
        erleichternde Rolle bei der Zahlung der gebuchten Buchungen. Der
        Transportvertrag wird zwischen dem Kunden und dem Beförderer nach einer
        Buchung durch den Kunden über taxionspot.nl abgeschlossen.
      </p>
      <p>
        Der Unternehmer haftet nicht für Schäden, einschließlich entgangener
        Gewinne, verlorener Daten, Personenschäden oder Sachschäden im
        Zusammenhang mit, im Zusammenhang mit oder anderweitig, die sich aus der
        Nutzung der (Transport-)Dienstleistungen der Fluggesellschaft ergeben.
        Diese Verantwortung und Haftung liegt bei der Fluggesellschaft gemäß
        Artikel 12 der Allgemeinen Geschäftsbedingungen für den Taxitransport
        von KNV Taxi.
      </p>
      <p>
        Der Unternehmer haftet nicht für Verzögerungen oder Mängel, die sich aus
        Gründen ergeben, die außerhalb seiner angemessenen Kontrolle liegen.
      </p>
      <p>
        Die Gesamthaftung des Unternehmers für dienstleistungendurchgebrachte
        Dienstleistungen an den Kunden übersteigt unter keinen Umständen 500
        Euro (500 Euro).
      </p>
    </Section>
    <Section heading="13) Datenschutz">
      <p>
        Entrepreneur ermöglicht es dem Kunden, ein Konto auf taxionspot.nl zu
        erstellen,in dem personenbezogene Daten ausgefüllt und gespeichert
        werden.
      </p>
      <p>
        Der Unternehmer respektiert die Privatsphäre des Kunden und behandelt
        die erhaltenen (persönlichen) Daten sorgfältig. Dies gemäß der
        Datenschutz-Grundverordnung (DSGVO).
      </p>
      <p>
        Bei der Buchung der Fahrt erklärt sich der Kunde damit einverstanden,
        dem Beförderer die erforderlichen personenbezogenen Daten zur Verfügung
        zu stellen.
      </p>
      <p>
        Unternehmer behält sich das Recht vor, sich an den Kunden für Dinge wie
        Newsletter, Gewinnaktionen, Geschenke, et cetera zu wenden. Dies kann
        sowohl on- als auch offline erfolgen, jedoch nur auf den
        (E-Mail-)Adressen des Kunden.
      </p>
    </Section>
    <Section heading="14) Geistiges Eigentum">
      <p>
        Alle geistigen Eigentumsrechte im Zusammenhang mit und/oder das Ergebnis
        der von Entrepreneur erbrachten Dienstleistungen liegen beim
        Unternehmer. Der Kunde erhält nur die durch diese Bedingungen und das
        Gesetz ausdrücklich gewährten Nutzungsrechte. Ein anderes oder sonstiges
        Recht ist ausgeschlossen.
      </p>
      <p>
        Die vom Unternehmer dem Kunden zur Verfügung gestellten Dokumente sind
        ausschließlich für die Nutzung durch den Kunden bestimmt. Kunden ist es
        nicht gestattet, erhaltene Informationen in irgendeiner Form
        offenzulegen und/oder zu vervielfachen. Dazu gehören das Bearbeiten,
        Verkaufen, Verfügbarmachen, Verteilen und Integrieren in Netzwerke,
        unabhängig davon, ob es nach der Verarbeitung oder nicht.
      </p>
      <p>
        Alle von Entrepreneur zur Verfügung gestellten Informationen bleiben
        Eigentum des Unternehmers.
      </p>
      <p>
        Der Unternehmer behält sich das Recht vor, das bei der Ausführung der
        Arbeiten erlangte Wissen für andere Zwecke zu nutzen, sofern keine
        vertraulichen Informationen des Kunden an Dritte weitergegeben werden.
      </p>
    </Section>
    <Section heading="15) Anwendbares Recht">
      <p>
        Für diese Allgemeinen Geschäftsbedingungen und die Vereinbarung zwischen
        Kunde und Unternehmer gilt niederländisches Recht.
      </p>
      <p>
        Alle Streitigkeiten, die sich aus diesen Allgemeinen
        Geschäftsbedingungen und/oder Vereinbarungen ergeben oder damit
        zusammenhängen, werden an das zuständige Gericht am Geschäftsort
        verwiesen.
      </p>
    </Section>
    <p style={{ color: "grey", fontSize: 14 }}>
      © Taxionspot
      <br />
      Houckgeeststraat 13 <br />
      5643 VS Eindhoven <br />
      +31 040 2405999 <br />
      <a style={{ color: "blue" }}>support@taxionspot.nl</a>
    </p>
  </div>
);
