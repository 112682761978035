import React from "react";
import Section from "./section";

export default () => (
  <div style={{ padding: 20 }}>
    <h2>General Conditions</h2> <br />
    <div>
      <b>
        Please read these terms and conditions carefully before using our
        services.
      </b>
    </div>
    <br />
    <Section heading="1) Definitions">
      <p>
        Terms and Conditions: This Terms and Conditions to the last established
        text.
      </p>
      <p>
        Entrepreneur: Taxionspot trading under the name Taxionspot.nl or taxion
        spot, located at Houckgeeststraat 13, 5643 USA in Eindhoven and
        registered with the Chamber of Commerce under number 65752376.
      </p>
      <p>
        Customer: Visitor, natural or legal person, who places one or more
        Bookings via taxionspot.nl and thus concludes a Transport Agreement with
        Carrier.
      </p>
      <p>Parties: Entrepreneur and Customer together.</p>
      <p>
        Booking: Placing one or more fare orders via taxionspot.nl in which The
        Entrepreneur forwards them to Carrier, and plays a facilitating role in
        the payment of the posted Booking(s).
      </p>
      <p>Ride: Transport booked by customer at Entrepreneur by taxi.</p>
      <p>
        Offer: The quote made by Entrepreneur following the placed ride order by
        Customer via taxionspot.nl.
      </p>
      <p>
        Carrier: A company that performs a Ride for Entrepreneur and for that
        purpose a Transport Agreement with Entrepreneur has been agreed.
      </p>
      <p>Driver: The person employed by Carrier to perform the Ride.</p>
      <p>
        Customer rate: The fare for a Ride that Customer charges from
        Entrepreneur.
      </p>
    </Section>
    <Section heading="2) Services">
      <p>
        Entrepreneur offers a technical platform that enables Customer to book
        and take rides from a Carrier.
      </p>
      <p>
        Entrepreneur acts as a mediator between Customer and Carrier and plays a
        facilitating role in the payment of the posted Booking(s).
      </p>
      <p>
        The transport agreement is established between Customer and Carrier by
        placing a fare order by Customer via taxionspot.nl..
      </p>
      <p>
        The identity of Carrier will be made known to Customer by email and text
        message immediately after booking the booked Ride.
      </p>
      <p>
        It is possible that the Carrier of a Ride will be changed, Customer will
        receive a timely message via an email and or text message.
      </p>
      <p>
        Customer acknowledges that Entrepreneur does not provide transport
        services or acts as a transport company and that the transport is
        provided by Carrier, which is not employed by Entrepreneur.
      </p>
    </Section>
    <Section heading="3) Applicability">
      <p>
        The Terms and Conditions apply to the booking and purchase of Journeys
        by Customer through taxionspot.nl..
      </p>
      <p>
        Customer accepts the applicability of these Terms and Conditions as soon
        as the Customer places a Booking through taxionspot.nl and therefore
        enters into a Transport Agreement with Carrier.
      </p>
      <p>
        The terms and conditions shall apply in full; these provisions can only
        be deviated from in writing after explicit written approval from The
        Entrepreneur.
      </p>
      <p>
        The Terms and Conditions will be made available to Customer digitally
        before making a final Booking.
      </p>
      <p>
        Unless expressly otherwise and in writing agreed, the applicability of
        other Terms and Conditions is excluded.
      </p>

      <p>
        If one or more provisions in these Terms and Conditions are at any time
        partially or completely annulled or could be destroyed, the remainder of
        these Terms and Conditions will remain fully applicable. In this case,
        the customer and entrepreneur will enter into consultations to agree on
        the replacement of the nun or destroyed provisions. The purpose and
        scope of the original provisions shall be observed as far as possible.
      </p>
      <p>
        In the case of uncertainty as to the interpretation of one or more
        provisions of these Terms and Conditions, the explanation should take
        place in the spirit of these conditions.
      </p>
      <p>
        Should a situation arise between Customer and Entrepreneur that is not
        regulated in these Terms and Conditions, this situation should be
        assessed in the spirit of these conditions.
      </p>
      <p>
        Entrepreneur may change these Terms and Conditions from time to time.
        The modified version will be published on taxionspot.nl and will apply
        to the Booking placed by Customer. The Customer is advised to read the
        Terms and Conditions (again) before posting a (new) Booking.
      </p>
    </Section>
    <Section heading="4) Customer requirements">
      <p>
        The minimum age to book a Ride through taxionspot.nl is 18 years or
        older. Customer declares that he is of age when booking the Ride.
        Entrepreneur has the right to verify the age of Customer. If this does
        not correspond to the actual age, the Entrepreneur has the right to
        terminate the Agreement with immediate effect and not to perform the
        Ride. Customer is not entitled to a refund in this case.
      </p>
      <p>
        When placing a ride order, customer fills in a valid email address and
        phone number to which he or she can be reached via taxionspot.nl.
        Customer is responsible for the accuracy of his data, as well as for the
        accuracy of posting a Booking.
      </p>
      <p>
        Entrepreneur is entitled to block Customer from taxionspot.nl and make
        booking Rides impossible. If entrepreneur decides to do so, Entrepreneur
        Customer will immediately inform by e-mail and or sms.
      </p>
    </Section>
    <Section heading="5) Entrepreneur obligations">
      <p>
        The entrepreneur will make every effort to ensure the proper functioning
        of taxionspot.nl,but cannot guarantee that itwill function fully and
        continuously at all times. Entrepreneur will always make maximum efforts
        to solve any (internet) malfunctions or other problems with
        functionality and accessibility as soon as possible.
      </p>
      <p>
        Customer can ask entrepreneur questions and, within 7 days of the
        performance of the Ride, file complaints about the handling of the
        Booking and/or the service of Carrier. After this period, taxionspot has
        the right to stop considering the complaint.
      </p>
      <p>
        Entrepreneur is responsible for processing customer payment in relation
        to the Booking posted by Customer. Entrepreneur bears the debtor risk in
        non-payment by Customer and is entitled to initiate debt collection
        measures.
      </p>
    </Section>
    <Section heading="6) Het Aanbod">
      <p>
        The Offer by Entrepreneur on behalf of Carrier to Customer is
        non-binding. Entrepreneur is entitled to change and adapt the Offer. If
        entrepreneur proceeds to this end, she informs Customer.
      </p>
      <p>
        Each offer contains such information that it is clear to the Customer
        what the rights and obligations are, which are attached to the
        acceptance of the Offer. Furthermore, the offer contains a complete and
        accurate description of the services offered. The description is
        sufficiently detailed to allow a good assessment of the offer by
        Customer.
      </p>
      <p>
        All specifications and details in the Offer are an indication from which
        no rights can be derived and cannot be grounds for compensation or
        termination of the contract.
      </p>
    </Section>
    <Section heading="7) Booking a ride">
      <p>
        Customer can book a Trip to Eindhoven with Entrepreneur throughout the
        Netherlands via the online booking system on taxionspot.nl. Customer can
        book a Ride for within 20 minutes or Customer can book a Ride for a
        later time.
      </p>
      <p>
        For Journeys from the airport, Customer must provide the flight details
        when placing the Ride Order so that Carrier can take this into account.
        If Customer has not passed on the flight details, the pick-up time
        specified by Customer is leading for the Carrier.
      </p>
      <p>
        After booking, Customer will receive an immediate confirmation of the
        Booked Ride at the specified email address and a text message on the
        specified phone number.. After the end of the journey, the Customer will
        receive an email address to leave a review.
      </p>
      <p>
        If the data transmitted by Customer does not turn out to be correct,
        Customer can adjust it on its overview page. If this does not work,
        Customer should contact taxionspot..
      </p>
      <p>
        Customer is responsible for the accuracy of his data, as well as for the
        accuracy of the posting and checking of his Booking. Entrepreneur is not
        liable for incorrect (flight) data transmitted by Customer.
      </p>
      <p>
        It is possible to bring extra baggage, special baggage during the Ride.
        If the taking away is not possible, carrier will contact Customer and a
        solution will be sought.
      </p>
      <p>
        If Customer takes so much luggage that it requires the use of a taxi van
        or several taxis, Customer must ensure that he books the correct
        transport category or multiple taxis. If this did not happen, taxionspot
        is entitled to charge additional fees.
      </p>
      <p>It is possible to take small children with you during the Ride.</p>
    </Section>
    <Section heading="8) Canceling or changing a ride">
      <p>
        Customer has until 2 hours before the agreed pick-up time the right to
        change or cancel the Ride. Any amounts paid will be refunded in full
        (minus any transaction costs). A cancellation must be communicated by
        telephone to the Carrier, as well as via the online cancellation
        environment of taxionspot.. Online cancellations are only possible when
        Customer has an account with taxionspot.
      </p>
      <p>
        If the Ride pickup location changes, you'll be charged an additional fee
        if the pickup location is further than the address previously specified.
        These must be settled in cash with Carrier. The same applies to stops
        that are not indicated at the Booking.
      </p>
      <p>
        If the Ride is cancelled less than 2 hours in advance, Customer is not
        entitled to a refund.
      </p>
      <p>
        Entrepreneur is entitled to cancel a booked Ride up to 24 hours in
        advance, for whatever reason. Customer will get the amount already paid
        back, but will then be responsible for arranging (and paying)
        replacement transport.
      </p>
    </Section>
    <Section heading="9) The performance of a ride">
      <p>
        Driver will pick up Customer at the agreed time, at the front door of
        the address provided by Customer. If this place is not accessible by
        vehicle, Chauffeur will park his vehicle as close as possible and inform
        Customer about this.
      </p>
      <p>
        For Journeys from the airport, Customer must call carrier after landing
        to tune where each other is affected.
      </p>
      <p>
        Customer should be able to identify themselves to Driver by receiving
        confirmation email address or confirmation sms.
      </p>
      <p>
        Customer is responsible for the accuracy of his data, as well as for the
        accuracy of the posting and checking of his Booking. If there are more
        passengers and/or baggage in the performance of the Ride than was
        completed by The Customer at the Time of Booking, the Driver is not
        obliged to bring the additional passengers and/or baggage.
      </p>
      <p>
        The Ride is carried out in accordance with the{" "}
        <a href="https://www.knv.nl/wp-content/uploads/2019/01/KNV_Taxi_av_taxi_september_2018_2paginas.pdf">
          General Conditions for Taxi Transport of trade association KNV Taxi.
        </a>
        &nbsp; If these Terms deviate from these Terms and Conditions in any
        respect, the latter will prevail.
      </p>
      <p>
        If 10 minutes after the agreed time no taxi has arrived at Customer,
        Customer should always contact Carrier first via the specified telephone
        number. If telephone contact is not successful or Carrier indicates that
        the taxi will not be there in time, Customer should contact via the
        emergency number of taxionspot. This number finds Customer on the
        website of taxionspot. A solution will then be sought together.
      </p>
    </Section>
    <Section heading="10) No show Customer and Waiting Time Driver">
      <p>
        Customer is not entitled to a refund if there is a 'no show'. There is a
        no show if Customer is not present at the agreed location within ten
        minutes of the agreed pick-up time. Carrier in the meantime is required
        to make demonstrable attempts to contact Customer.
      </p>
      <p>
        In the case of transport from the airport, there is a different waiting
        time for Chauffeur, i.e. one hour and ten minutes after landing of the
        Customer's flight. The flight data that has been passed on by Customer
        at Booking serve as a starting point. Carrier will keep an eye on the
        flight. Customer is also required to keep Carrier informed of delays at
        the airport, using the telephone number that is on his confirmation
        email address. If Customer has not provided a correct or correct flight
        number, Article 10.1 will automatically enter into force. The completed
        pick-up time is maintained.
      </p>
      <p>
        In case of a longer waiting time than one hour and ten minutes after
        landing of the aircraft, Chauffeur is entitled to no longer have to wait
        and it is considered a no show. In consultation with Customer it can be
        agreed to wait. The Driver is entitled to ask for a waiting fee, this
        can be settled in cash in the taxi.
      </p>
      <p>
        If the actual landing time is so different (at least 3 hours) from the
        original landing time that it becomes difficult for Carrier to perform
        the Ride, a solution can be sought in consultation with taxion spot.
        Should this not work, Carrier is entitled not to perform the Ride and
        the Ride will be cancelled. Customer will get back the full amount paid
        (minus any transaction costs), but will be responsible for arranging
        (and paying) replacement transport.
      </p>
    </Section>
    <Section heading="11) Payment">
      <p>
        A Trip booked by Customer with Entrepreneur must be paid in advance to
        Entrepreneur. The Booking is not final until it is paid.
      </p>
      <p>
        Payment can be made through various payment methods. Entrepreneur can
        charge a surcharge for certain payment methods. If a surcharge applies,
        this will be mentioned in the booking process for payment methods.
      </p>
      <p>
        Tolls and ferry fees, as well as costs for extra kilometres driven by
        changing the route by Customer after he has boarded with Chauffeur, must
        be paid directly to Chauffeur.
      </p>
      <p>
        Any tip is not included in the price. The customer is free to give the
        Driver a tip and the amount of the tip.
      </p>
    </Section>
    <Section heading="12) Liability">
      <p>
        Entrepreneur acts as a mediator for Carrier and plays a facilitating
        role in the payment of the posted Booking(s). The transport agreement is
        concluded between Customer and Carrier after placing a Booking by
        Customer via taxionspot.nl..
      </p>
      <p>
        The entrepreneur shall not be liable for any form of damage, including
        lost profits, lost data, personal injury, or property damage in relation
        to, in connection with, or otherwise arising from the use of the
        (transport) services offered by Carrier. This responsibility and
        liability rests with the Carrier, in accordance with Article 12 of the
        General Conditions for Taxi Transport of KNV Taxi.
      </p>
      <p>
        The entrepreneur is not liable for any delay or deficiency arising from
        causes beyond her reasonable control.
      </p>
      <p>
        Under no circumstances will the entrepreneur's total liability for
        services provided to Customer exceed 500 euros (€500).
      </p>
    </Section>
    <Section heading="13) Privacy">
      <p>
        Entrepreneur enables Customer to create an account on
        taxionspot.nl,where personal data is filled in and stored.
      </p>
      <p>
        Entrepreneur will respect the privacy of Customer and handle the
        obtained (personal) data carefully. This in accordance with the General
        Data Protection Regulation (GDPR).
      </p>
      <p>
        When booking the Ride, Customer agrees to provide the necessary personal
        data to Carrier.
      </p>
      <p>
        Entrepreneur reserves the right to approach Customer for things like
        newsletters, win promotions, presents, et cetera. This can be done both
        on- and offline, but only on the (mail) addresses provided by Customer.
      </p>
    </Section>
    <Section heading="14) Intellectual property">
      <p>
        All intellectual property rights relating to and/or the result of the
        services provided by Entrepreneur rest with the Entrepreneur. Customer
        only obtains the rights of use expressly granted by these terms and the
        law. Any other or further right is excluded.
      </p>
      <p>
        The documents provided to Customer by Entrepreneur are intended solely
        to be used by Customer. Customers are not allowed to disclose and/or
        multiply obtained information in any form. This includes editing,
        selling, making available, distributing and integrating it into
        networks, whether or not after processing.
      </p>
      <p>
        All information provided by Entrepreneur remains the property of the
        Entrepreneur.
      </p>
      <p>
        The entrepreneur reserves the right to use the acquired knowledge that
        has been established during the execution of the work for other
        purposes, provided that no confidential information of Customer is
        provided to third parties.
      </p>
    </Section>
    <Section heading="15) Applicable law">
      <p>
        Dutch law applies to these Terms and Conditions and the Agreement
        between Customer and Entrepreneur.
      </p>
      <p>
        Any dispute arising from or related to these Terms and Conditions and/or
        Agreement will be referred to the competent court in the place of
        business.
      </p>
    </Section>
    <p style={{ color: "grey", fontSize: 14 }}>
      © Taxionspot
      <br />
      Houckgeeststraat 13 <br />
      5643 VS Eindhoven <br />
      +31 040 2405999 <br />
      <a style={{ color: "blue" }}>support@taxionspot.nl</a>
    </p>
  </div>
);
